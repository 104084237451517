<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="wrap">
      <div class="h1">
        2024年贵州省放射影像专业医疗质量控制工作会议<br />
        暨基于病例的质控培训第五期成功举办
      </div>
      <section>
        <div class="div_p">
          2024年12月15日，贵州省放射影像专业医疗质量控制工作会议暨基于病例的质控培训第五期在贵阳市隆重召开。质控工作会议以“影像质控与创新发展”为主题。中华医学会放射学分会候任主任委员、国家放射影像专业质控中心副主任、北京医院放射科主任陈敏教授，国家放射影像专业质控中心副主任、北京协和医院西院放射科主任薛华丹教授，中山大学附属第一医院冯仕庭教授等国内著名专家参会指导并现场授课，贵州医科大学附属医院质管处处长莫非、贵州医科大学附属医院副院长黄海、贵州省卫生健康委医政处副处长张意出席会议，来自全省9个市州115家医疗机构共256位影像同仁现场参会，1000余人线上参会交流。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241215/1.png" alt="">
        </div>
        <div class="div_p">
          莫非表示，医疗质量是医疗工作的生命线,加强医疗质量控制、持续提高医疗服务水平是医疗卫生服务的核心工作，对放射影像专业质控中心一年来取得的工作成绩给予了充分肯定并对2025年质控工作提出了具体要求，医院质管处将全力支持、积极配合，为质控中心的各项工作提供坚实的支持和保障。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241215/2.png" alt="">
        </div>
        <div class="div_p">
          黄海指出，现代医学体系中放射影像学科的重要性不言而喻，放射影像质控工作任务艰巨但意义重大，对促进区域医疗公平性、确保医疗质量与同质化服务水平至关重要，希望放射影像专业质控中心继续加大监督与规范化、同质化培训力度，持续提升各级医疗机构的放射影像服务能力，并对一直以来关心和支持我院发展以及贵州省放射影像事业进步的各界人士表示衷心的感谢。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241215/3.png" alt="">
        </div>
        <div class="div_p">
          张意强调，我们必须深刻认识到医疗质量持续提升与同质化发展在贵州的重大意义，一年来，贵州省放射影像专业质控工作取得了巨大进步，在保障全省医疗机构影像检查技术和服务质量同质化发挥重要的作用，希望贵州省全体放射影像质控工作同道继续努力，全力以赴，助力全省人民群众获得更安全、高效、优质的医疗服务，为健康贵州建设添砖加瓦！
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241215/4.png" alt="">
        </div>
        <div class="div_p">
          专题培训环节，首先，陈敏教授作了题为《精准影像
          质控先行》的精彩讲座。围绕精准医疗的大背景，深入剖析了影像质控在提升诊断准确性和效率方面的重要作用，内容丰富、见解独到，为与会者提供了宝贵的经验参考和启示。其次，薛华丹教授带来了《国家放射影像专业质控体系解读》的专题讲座，她详细阐述了国家放射影像专业质控体系的建设构架、2024版放射影像专业医疗质量控制指标的意义和作用，以及国家放射影像专业质控中心未来战略目标与工作思路，为贵州放射影像专业质控工作同道下一步工作指明了方向、坚定了信心、提供了思路。随后，冯仕庭教授以《肝脏MR成像关键技术与质控》为题，结合自身丰富的临床经验和研究成果，以肝细胞癌影像精准诊断为例分享了肝脏MR成像的最新技术和质控要点，为与会同道深入讲解了肝脏MR成像的关键步骤和注意事项，为全面提升与会者肝脏MR成像水平与诊断能力具有重要的指导和实践参考价值。贵州省卫生健康委信息中心高级工程师王帅作了题为《贵州省检查检验结果共享互认实践与影像质控思考》的报告，详细介绍了贵州省在检查检验结果共享互认方面的探索和实践，以及影像质控工作的现状，对未来智能质控工作提出了切实可行的建议和指导。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241215/5.png" alt="">
          <img src="@/assets/HY20241215/6.png" alt="">
          <img src="@/assets/HY20241215/7.png" alt="">
          <img src="@/assets/HY20241215/8.png" alt="">
        </div>
        <div class="div_p">
          质控工作汇报环节，来自九个市州放射影像专业质控中心主任依次进行了各自质控中心的工作汇报和2024年国家放射影像专业7项质控指标推进经验分享。他们结合各自市州的实际情况，分享了质控工作的具体做法和取得的成效，并进行了热烈讨论，为与会者提供了丰富的实践经验，内容丰富且具有很强的指导和参考价值。薛华丹教授对汇报环节进行了指导和点评，并一一给出了许多建设性意见和建议，为与会者提供了更广阔的工作思路和视角，对推动贵州放射影像质控工作持续提升与全省同质化具有重要指导作用。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241215/9.png" alt="">
          <img src="@/assets/HY20241215/10.png" alt="">
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241215/11.png" alt="">
          <img src="@/assets/HY20241215/12.png" alt="">
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241215/13.png" alt="">
          <img src="@/assets/HY20241215/14.png" alt="">
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241215/15.png" alt="">
          <img src="@/assets/HY20241215/16.png" alt="">
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241215/17.png" alt="">
          <img src="@/assets/HY20241215/18.png" alt="">
        </div>

        <div class="div_p">
          随后，备受瞩目的基于病例的质控培训迎来了第五期，会议由影像科阮志兵教授、陈静副教授、于静副教授主持。
        </div>
        <div class="three-img">
          <img src="@/assets/HY20241215/19.png" alt="">
          <img src="@/assets/HY20241215/20.png" alt="">
          <img src="@/assets/HY20241215/21.png" alt="">
        </div>
        <div class="div_p">
          会议伊始，薛华丹教授以《急腹症影像诊断思路》为题，通过丰富的临床病例及结合自身丰富工作经验进行了深入浅出的详细讲解，尤其详细阐述了基于解剖部位的急腹症影像诊断思路，同时强调临床病史与影像检查技术选择策略对影像精准诊断的重要性，与会者收获满满。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241215/22.png" alt="">
        </div>
        <div class="div_p">
          随后，铜仁市人民医院的杨昌义副主任医师汇报了一例关于腹部钝性损伤的精彩病例。他通过详实介绍病例的首诊原因、影像检查技术、影像诊断及全过程诊疗，并结合个人经验深入反思与思考急诊影像检查中存在的困惑和挑战、不足或问题，并提出了切实可行的解决方案供大家参考。来自铜仁市人民医院史天亮教授、遵义医科大学附属医院李邦国教授以及我院影像科刘静副教授、急诊外科夏飞教授、胃肠外科王黔教授等多位专家围绕该病例从各自专业角度展开了热烈的讨论与深入交流，分享了独到见解与临床经验，提出了宝贵的意见和建议。通过病例分析讨论，临床专家不仅加深了对影像科急诊工作流程与现实困难，影像同道亦丰富了临床知识以及临床所需要的影像检查结果，加深了影像科与相关临床科室间的了解，对全面提升临床急腹症患者精准诊疗水平具有重要的价值。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241215/23.png" alt="">
          <img src="@/assets/HY20241215/24.png" alt="">
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241215/25.png" alt="">
          <img src="@/assets/HY20241215/26.png" alt="">
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241215/27.png" alt="">
          <img src="@/assets/HY20241215/28.png" alt="">
        </div>
        <div class="div_p">
          专家点评嘉宾，中山大学附属第一医院影像科主任冯仕庭对此次病例进行了精彩独到点评，并结合自身丰富的临床工作经验分享了影像科如何通过加强质控工作助力影像精准诊断，并对自身临床教学心得体会进行了介绍，对与会同道具有很好的启示和宝贵借鉴作用。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241215/29.png" alt="">
        </div>
        <div class="div_p">
          最后，贵州省放射影像专业质控中心高波主委总结发言，为本次会议画上了圆满句号。高波强调了基于病例的质控培训活动的重要性和必要性，希望通过此类质控与培训，切实加强医学影像临床服务工作全流程的质量控制水平和同质化服务能力，为患者提供更加精准、快捷、安全的医疗服务。并对与会专家和嘉宾在会议中的积极贡献深表谢意，感谢他们在推动医学影像质控事业发展中所扮演的重要角色。展望未来，高波提出了新一年贵州省放射影像质控工作计划，以发现问题、解决问题为导向，将进一步完善全省质控体系与智能质控建设，加强质控与递进式同质化专业能力提升培训助力放射工作的规范化和标准化，持续提升全省影像质控工作成效，推动全省影像检查结果互认工作。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241215/30.png" alt="">
        </div>
        <div class="div_p">
          本期放射影像专业医疗质量控制工作会议暨基于病例的质控培训会议的成功举办，不仅为贵州放射同道提供了一个学习交流的平台，也为全省放射影像专业质控工作的持续发展注入了新的活力和动力。未来，我们将继续加强质控工作的交流与合作，共同提升贵州放射影像专业质控工作水平，为人民群众的健康福祉作出更大的贡献。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241215/31.png" alt="">
        </div>

      </section>
    </div>


  </div>
</template>

<script>
export default {
  name:"HY20241215",
}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin: 10px 0;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_h2 {
  font-weight: 600;
}

.div_img {
  text-align: center;
}

.div_tit {
  text-align: center;
}

.vertical-img img {
  width: 50% !important;
  display: inline-block;
}
.three-img img {
  width: 30% !important;
  display: inline-block;
}

.div_footer {
  text-align: right;
}
</style>